import React from 'react';
import styled from 'styled-components';
import Works from '../../components/work-v2/index';
import PageRoot from '../../components/common-components/templates/page-root-v2';
import '../../styles/foundation-v2.css';
import SEOHeaderV2 from '../../components/common-components/templates/page-root-v2/seo-v2';

const WorksOuter = styled.div`
  width: 100%;
  @media (max-width: 1023px) {
    margin-top: 83px;
    margin-bottom: 80px;
  }
  @media (min-width: 1024px) {
    margin-top: 236px;
    margin-bottom: 100px;
  }
`;
const WorksPage = () => {
  return (
    <PageRoot>
      <SEOHeaderV2 pageTitle="実績紹介" />
      <WorksOuter className="wrapper-v2">
        <Works />
      </WorksOuter>
    </PageRoot>
  );
};
export default WorksPage;
